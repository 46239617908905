import React from "react";
import './ToggleSwitch.scss';

function ToggleSwitch({checked, onChange}) {

    const setCheckboxValue = ({ target: { checked } }) => {
        onChange(checked);
    };

    return (
        <div className="toggle-switch">
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                name="toggleSwitch"
                id="toggleSwitch"
                checked={checked}
                onChange={setCheckboxValue}
            />
            <label className="toggle-switch-label" htmlFor="toggleSwitch">
                <span className="toggle-switch-inner" />
                <span className="toggle-switch-switch" />
            </label>
        </div>
    );
}

export default ToggleSwitch;