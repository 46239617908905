import React, {useState} from 'react'
import './Test.scss';
import './SignupForm.scss';
import {ProductCreateModal} from "../../components/ProductCreateModal";


function Test() {

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    return (
        <div>
            <button onClick={openModal}>Open Modal</button>
            <ProductCreateModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
        </div>
    );
}


export default Test;