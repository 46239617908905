import React from 'react';
import * as PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {LuLayoutDashboard} from "react-icons/lu";
import {BiArchive} from "react-icons/bi";
import './MainContainer.scss';

export const MainContainer = ({children}) => {
    return (
        <div className="crm__main-container">
            <div className="crm__nav">
                <Link to="/test">
                    <div className="crm__nav-item">
                        <LuLayoutDashboard color='white' size="24px"/>
                    </div>
                </Link>
                <Link to="/products">
                    <div className="crm__nav-item">
                        <BiArchive color='white' size="24px"/>
                    </div>
                </Link>
            </div>
            <div className="crm__content">
                {children}
            </div>
        </div>
    );
};

MainContainer.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.symbol,
    ]),
    info: PropTypes.node,
    className: PropTypes.string,
    hasFocus: PropTypes.bool,
    isValidating: PropTypes.bool,
};
