import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';
import './HeaderNav.scss';
import {IoMdExit} from "react-icons/io";
import {Link} from "react-router-dom";

export const HeaderNav = ({title, children}) => {
    useEffect(() => {
        document.title = title + " | TFCRM";
    }, []);

    return (
        <div className="crm__header-nav">
            <div className="crm__header-nav-left">
                <h1>{title}</h1>
                {children}
            </div>
            <div className="crm__header-nav-right">
                <span className="crm__header-nav-user">Бігун Владислав Олегович №0001</span>
                <Link to="/logout">
                    <div className="crm__nav-item">
                        <IoMdExit size="24px" color="var(--logo-blue)"/>
                    </div>
                </Link>
            </div>
        </div>
    );
};

HeaderNav.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.symbol,
    ]),
    info: PropTypes.node,
    className: PropTypes.string,
    hasFocus: PropTypes.bool,
    isValidating: PropTypes.bool,
};
