import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';
import './ItemList.scss';
import {Link, useNavigate} from "react-router-dom";
import {MdEdit} from "react-icons/md";
import {FaRegTrashAlt} from "react-icons/fa";
import {TextField} from "../field-components/TextField";
import {Form, Formik, useFormikContext} from "formik";
import {CategoryProductField} from "../field-components/CategoryProductField";
import {ButtonAdd} from "../ButtonAdd/ButtonAdd";

export const ItemList = ({handleDelete, products, productCategories, openModal, setProductEdit}) => {
    const navigate = useNavigate();

    const items = [ {label:'Не вказано', value: 0}, ...productCategories]

    const linkItem = (id) => {
        // console.log(this );
        navigate('show/' + id)
    }

    const { values, submitForm } = useFormikContext();

    useEffect(() => {
        if (values) {
            submitForm();
        }
    }, [values, submitForm]);

    const getProductEdit = (item) => {
        setProductEdit(item)
        openModal(true)
    }
    return (
        <div className="crm__item-list">
            <div className="crm__item-list-filter">
                <Form>
                    <div className="tf-form__row">
                        <div className="tf-form__col w33">
                            <TextField name="search" placeholder="Введіть назву або код товару"
                                       label="Пошук"/>
                        </div>
                        <div className="tf-form__col w33">
                            <CategoryProductField name="category_id" label="Категорія" items={items}
                                                  placeholder="Категорія"/>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="crm__item-list-table-wrapper">
                <table className="crm__item-list-table">
                    <thead>
                    <tr>
                        <th>Назва</th>
                        <th>Код</th>
                        <th>Ціна</th>
                        <th>Категорія</th>
                        <th>Активно</th>
                        <th width="122px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((item, key) => {
                        return (
                            <tr key={key}>
                                <td>{item.name}</td>
                                <td>{item.vendor_code}</td>
                                <td>{item.price}</td>
                                <td>{item?.category.name}</td>
                                <td>{item.active ? 'Так' : 'Ні'}</td>
                                <td>
                                    <ButtonAdd onClick={() => getProductEdit(item)}>
                                        <MdEdit />
                                    </ButtonAdd>
                                    <ButtonAdd onClick={() => handleDelete(item.id)}>
                                        <FaRegTrashAlt/>
                                    </ButtonAdd>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ItemList.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.symbol,
    ]),
    info: PropTypes.node,
    className: PropTypes.string,
    hasFocus: PropTypes.bool,
    isValidating: PropTypes.bool,
};
