import React, {useEffect, useState} from 'react';
import ProductCategory from "./api/ProductCategory";
import {AssetsContext} from "./context/AssetsContext";

const Assets = ({children}) => {
    const [items, setItems] = useState({
        productCategories: [],
    });



    return (
        <AssetsContext.Provider value={items}>
            {children}
        </AssetsContext.Provider>
    )
}

export default Assets;
